import React from "react";
import Ilink from "./Ilink";
import Web from "../img/nick.jpg";


let webdev = ["I am a freelance web developer based in Toronto Canada. I work with agencies , designers and clients.", 
"I studied computer science at Ryerson University with a focus on database design and structure.",
"Most of the time I work with the LAMP stack on the back-end. On the front end it is all javascript ES6, CSS and good old HTML."];
let stack = "Javascript, PHP, Unix, MySQL, MongoDB, React, DOJO, jQuery, THREE.js, SCSS";



function Biol(props){

    return (
      <section className={props.classN} id="bio">
        <h1>Nick Andrews: About me.</h1>
       <div className="split-left">
          <div className="logos">
          
           
          </div>
      
          <Ilink  title={"Intermediate Web Developer"} url={null} body={webdev} date={null} />
          <Ilink  title={"Skill Stack" } url={null} body={stack} date={null} />
        </div>
       
     </section>
    );
  
}

export default Biol;