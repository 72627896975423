import React from "react";
import Stringa from "./Stringa";


function Ilink(props) {
 
    return (
	    <article className="ilink">
	      <h3>{props.title}</h3>
	      <h4 className={props.date===null?"hide-this":"show-this"}>{props.date}</h4>
		     <Stringa body={props.body} />
	      <a href={props.url} className={props.url===null?"hide-this":"show-this"}>View Project</a>
	    </article>
	  );
  	
}

export default Ilink;