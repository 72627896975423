import {useState} from "react";

import Stringa from "./Stringa";

import Web from "../img/WebGL.svg";
import Thr from "../img/three.svg";
import Rct from "../img/react.svg";
import Bld from "../img/blender.svg";

const data = [
  {
    title:"Online Product Configurator",
    url:"/3D/configurator/",
    date:"2021-04",
    content:["This is a software tool I designed for the building industry.",
" It contains a suite of unique algorithms allowing users to customise a garden tool cabin/shed model. Users can change the color, dimensions, the dormer position and window and door features.",
" Users can also view a representation of their model in day or in a night time setting.",
"An image screen shot of their work can be downloaded. Data used to produce the model can be exported to a database in JSON format. This can then be used to develop a pricing quote and provide a tracking database ID reference.",
"This scene takes a while to load the first time because, well, there is a lot of components to be cached."]
  },
   {
    title:"React-three-fiber Experiment",
    url:"/3D/r3f/",
    date:"2021-05",
    content: ["This is an experiment with the React framework that combines the component based model with the Three.js webgl renderer.",
" I built a quadtree data structure in order to experiment with. Quadtrees are used in geospatial planning and in computer gaming applications.",
"The scene is a simple game where the objective is to clean up the garbage."]
  }


]

function Webgl (props){

    const [selected, setSelected] = useState(null);
    
    const toggle=(i)=>{
      if(selected===i){
        return setSelected(null);
      }
      setSelected(i);
    }

    return (
      
      <section className={props.classN} id="webgl">
        <h1>Webgl Projects in 3D</h1>
        <div className="split-left">
          <div className="logos">
            <img src={Web} alt="Webgl Logo" className="web" />
            <img src={Thr} alt="Three.js Logo" className="thr" />
            <img src={Rct} alt="React Logo" className="rct" />
            <img src={Bld} alt="Blender Logo" className="bld" />
          </div>
           <p className="super">
           I build 3D web pages.  Most of my effort has been engaged using the Three.js library 
           and developing 3D modelling skills in the wonderful Blender application.
           </p>
        </div>
         <div className="accordion">
          {data.map((item, i)=>(
              <article className="blink" key={"ecomm"+i}>
                <div className="title" onClick={() => toggle(i)}>
                  <h3>{item.title}</h3>
                  <span>{selected===i?'-':'+'}</span>
                </div>
               <div className ={selected===i?'open':'close'}>
                  <h4 className={item.date===null?"hide-this":"show-this"}>{item.date}</h4>
                    <Stringa body={item.content} />
                    <a href={item.url} className={item.url===null?"hide-this":"show-this"}>View Project</a>
               </div>
               </article>
           ))}

         </div>  
      </section>
    );
  
}

export default Webgl;