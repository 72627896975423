import React from "react";


class Contact extends React.Component{
  github=(props)=>{
    window.location.href="https://github.com/Nick-Andrews";
  }
  linked=(props)=>{
    window.location.href="https://www.linkedin.com/in/nick-andrews-a9236115/";
  }
  render(){
    return (
      <aside className="footer-section" >

       
         <div className="split-left">
            
            <div className="gt" onClick={this.github} />
            <div className="lk" onClick={this.linked}/>
            
         </div>
     </aside>
    );
  }
}

export default Contact;