import React from "react";

const Mlink = (props)=>{
  return(
    <li className={props.clss} 
    id={props.id}

     onMouseDown={ props.eventr } 
     onMouseUp={ props.eventr } 
    >
        {props.nom}
    </li>
  );
}


function Menu(props){


    return (
      <nav className="portfolio-menu">
       
        <ul>
          <Mlink 
          eventr = {  props.webgy  }
         
          url={"./3D/"} 
          nom={"3D projects"} 
          id="wblink"
          clss={(props.hotpos===0)?"mlink hot":"mlink"}          
          />

  
          <Mlink 
          eventr = {  props.wpre  }
          
          url={"./Wordpress/"} 
          nom={"Wordpress"}
          id="wplink"
          clss={(props.hotpos===1)?"mlink hot":"mlink"}
          />

          <Mlink 
          eventr = { props.ecomm } 
        
          url={"./E-Commerce/"}
          nom={"E Commerce"}
          id="eclink" 
          clss={(props.hotpos===2)?"mlink hot":"mlink"}
          />
         <Mlink 
          eventr = {  props.mee  }
         
          
          url={"./me/"} 
          nom={"me"}
          id="melink"
          clss={(props.hotpos===3)?"mlink hot":"mlink"}
          />
         
        </ul>
      </nav>
    );
  
}

export default Menu;