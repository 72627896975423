import {useState} from "react";

import Stringa from "./Stringa";

import Woologo from "../img/woo.svg";
import Shop from "../img/shopify.svg";

const data = [
  {
    title:"Shopify Store : Fine Textiles Retailer",
    url:"https://leostextiles.com",
    date:"2020-04",
    content:["I developed the online store for Leo's Textiles, an exquisite and fine fabric retailer. ",
      "My work involved working closely with the store staff to capture pricing, category and textile description data. This was then collated with imagery using a wordpress dev site.",
      " The purpose of this was so the store staff could be on the floor using their cell phones to update the data model in a structured fashion while still operating the retail business. ",
      "Once we had the data model the catalog of products was uploaded to the Shopify CDN using the product importation feature."]
  },
   {
    title:"WOO Commerce setup: Fashion Designer",
    url:"https://www.hoaxcouture.com",
    date:"2020-05",
    content: ["When the global COVID-19 pandemic hit many businesses pivoted to address the crisis.",
" One of my long-term clients is a bespoke Toronto Fashion Design house. They started making cloth masks.",
"I helped them get woocommerce set up on their existing Wordpress website so they could continue to work in a time when they could not meet directly with clientele."]
  }


]

function Ecomm (props){

    const [selected, setSelected] = useState(null);
    
    const toggle=(i)=>{
      if(selected===i){
        return setSelected(null);
      }
      setSelected(i);
    }

    return (
      
      <section className={props.classN} id="ecomm">
        <h1>Ecommerce Projects</h1>
        <div className="split-left">
          <div className="logos">
            <img src={Woologo} alt="Woocommerce Logo"  className="woo"/>
            <img src={Shop} alt="Shopify Logo"  className="shop"/>
          </div>
           <p className="super">Shopify and Woo commerce are the two predominant e-commerce systems used presently. I have worked with both and can advise you on the strengths and weaknesses of each system.</p>
        </div>
         <div className="accordion">
          {data.map((item, i)=>(
              <article className="blink" key={"ecomm"+i}>
                <div className="title" onClick={() => toggle(i)}>
                  <h3>{item.title}</h3>
                  <span>{selected===i?'-':'+'}</span>
                </div>
               <div className ={selected===i?'open':'close'}>
                  <h4 className={item.date===null?"hide-this":"show-this"}>{item.date}</h4>
                    <Stringa body={item.content} />
                    <a href={item.url} className={item.url===null?"hide-this":"show-this"}>View Project</a>
               </div>
               </article>
           ))}

         </div>  
      </section>
    );
  
}

export default Ecomm;