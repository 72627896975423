import {useState} from "react";

import Stringa from "./Stringa";

import Wplogo from "../img/wp.svg";

const data = [
  {
    title:"National Arts Organisation Funding Search Engine",
    url:"https://artsunite.ca",
    date:"2020-06",
    content:["I was contracted to develop a custom search engine tool for the Toronto based arts organisation Artscape Inc..", 
    "The search system is integrated into their Wordpress website www.artsunite.ca.",
    "Building the tool involved developing custom PHP class files that managed how information was collected from their existing database of Wordpress posts. Front end and back end interfaces were developed using a combination of javascript and PHP template files."]
  },
   {
    title:"Financial Advisor and Author",
    url:"https://standup.today",
    date:"2019-03",
    content: ["John De Goey is one of the senior leaders in Canada's financial planning industry.",
    " He is a published author and regular contributor to BNN/Bloomberg news broadcasts.",
    "I worked with him directly to build a website showcasing his work and thoughts as an author and frequent critic of current practices within his industry."]
  },
  {
   title:"Health Care Communications Professional",
    url:"http://arccommsinc.com",
    date:"2021-04",
    content:["I used Wordpress to set up a brochure website for a senior Health Care Communications expert.",
    "Apart from being capable of producing very elaborate websites. Wordpress can also be used for less complex projects." 
    ,"This is one of the main benefits of Open-Source software like Wordpress."]
  },
  {
   title:"Custom Google Maps API",
    url:"/Wordpress/map/",
    date:"2013-06",
    content:["I was contracted by an award winning graphic design agency(Bruce Mau Design) to create a custom Google maps interface for their client, a real estate developer, using Wordpress.",
"The idea was to have the map show the buildings they owned in the Toronto's downtown neighbourhoods.",
"When you hover a mouse over one of the locations on the map, a zoomed magnification appears so that you can precisely select a property.",
"The design house no longer manages the client's online presence and the website has since changed.", "I have kept an example of the map UI created here."]
  },
   {
   title:"Lifestyle blogger",
    url:"https://www.loulou.to",
    date:"2013-06",
    content:["I built a custom Wordpress theme for this prolific Toronto blogger that features a unique banner header that changes lighting and festive scenery as the months, days and hours progress. If you visit during the daytime the lighting is daylight. At night it is dark. The scenery changes with the seasons.","This website now generates so much traffic it has begun to run Google advertising."]
  },


]

function Wpress (props){
 

    const [selected, setSelected] = useState(null);
    
    const toggle=(i)=>{
      if(selected===i){
        return setSelected(null);
      }
      setSelected(i);
    }

    return (
      


      <section className={props.classN} id="wpress">
        <h1>Wordpress Projects</h1>
        <div className="split-left">
          <div className="logos">
            <img src={Wplogo} alt="Wordpress Logo" className="wp" />
          </div>
           <p className="super">Wordpress is the most widely used Content Management System(CMS) on the internet. I have developed a really solid understanding of the Wordpress architecture. I can use it to develop any sort of web project you may envision.</p>
        </div>

         <div className="accordion">
          {data.map((item, i)=>(
              <article className="blink" key={"wpress"+i}>
                <div className="title" onClick={() => toggle(i)}>
                  <h3>{item.title}</h3>
                  <span>{selected===i?'-':'+'}</span>
                </div>
               <div className ={selected===i?'open':'close'}>
                  <h4 className={item.date===null?"hide-this":"show-this"}>{item.date}</h4>
                    <Stringa body={item.content} />
                    <a href={item.url} className={item.url===null?"hide-this":"show-this"}>View Project</a>
               </div>
               </article>
           ))}

         </div>  



      </section>
    );
  
}

export default Wpress;