import './style.css';
import Menu from './list/Menu';
import Wpress from './list/Wpress';
import Ecomm from './list/Ecomm';
import Webgl from './list/Webgl';
import Bio from './list/Bio';
import Contact from './list/Contact';

import {useState} from "react";

import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';

ReactGA.initialize('G-BGP3ETZQ9S', { debug: false});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [webgly, setWebgl] = useState(true);
  const [ecommy, setEcommy] = useState(false);
  const [wpress, setWpress] = useState(false);
  
  const [mey, setMe] = useState(false);
  const [ht, hotMe] = useState(0);



  let meer=()=>{
  	setEcommy(false);
  	setWpress(false);
  	setWebgl(false);
  	setMe(true);
  	hotMe(3);
  }

  let wpre=()=>{

  	setEcommy(false);
  	setWpress(true);
  	setWebgl(false);
  	setMe(false);
  	hotMe(1);
  }
  let ecommr=()=>{

  	setEcommy(true);
  	setWpress(false);
  	setWebgl(false);
  	setMe(false);
  	hotMe(2);
  }
  let webg=()=>{

  	setEcommy(false);
  	setWpress(false);
  	setWebgl(true);
  	setMe(false);
  	hotMe(0);
  }

  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Nick Andrews | Portfolio</title>
          <link rel="canonical" href="https://portfolio.websolutions.to" />

      </Helmet>
      <Menu ecomm={ecommr}  wpre={wpre} webgy={webg} mee={meer} hotpos={ht}/>
        <header className="App-header"/>
         <Webgl classN={webgly?"portfolio-section hot":"portfolio-section hide-this"}/> 

         <Wpress classN={wpress?"portfolio-section":"portfolio-section hide-this"}/> 
         <Ecomm classN={ecommy?"portfolio-section":"portfolio-section hide-this"}/> 
      
         <Bio classN={mey?"bio-section":"bio-section hide-this"}/> 

      
       <footer>
        <Contact />
      </footer>

    </div>
  );
}

export default App;
