import React from "react";
import {map} from 'lodash';

const Stringa =(props)=>{
	let nut;
	switch (typeof(props.body) ){
		case "string":
			nut= <p>{props.body}</p>;
			break;
		case "object":
			nut= map(props.body, (el, i) => {
				 return <p key={i+Math.random()}>{el}</p>;
			});
			break;
		default:
			nut= <p>{props.body}</p>;
			break;
	}

	return nut;
}

export default Stringa;